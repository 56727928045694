.aboutDescription {
  composes: courseDescription from "../common/styles.module.css";
  padding-right: 5vw;
}

.menuButton {
  float: "right";
}

.hide {
  display: "none";
}

.drawer {
  flex-shrink: 0;
}

.drawerPaper {
  overflow-x: hidden;
  background: #21232c !important;
}

.aboutHeader {
  color: #fa4616;
  font-family: "heavitasregular";
  font-weight: normal;
  margin-bottom: 5vh;
}

.heroImageContainer {
  max-height: 524px;
  width: auto;
  background: #000000;
  margin-bottom: 0;
  float: right;
  opacity: 1;
}

@media only screen and (min-width: 320px) {
  .aboutHeader {
    composes: aboutHeader;
    padding-right: 20vw;
    font-size: 30px;
    line-height: 1;
    padding-left: 5vw;
  }

  .drawer {
    width: 100%;
  }

  .drawerPaper {
    overflow-x: hidden;
    width: 100%;
    margin-top: 10%;
    background: #21232c !important;
  }

  .heroImageContainer {
    composes: heroImageContainer;
    opacity: 0.5;
  }

  .aboutDescription {
    composes: courseDescription from "../common/styles.module.css";
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@media only screen and (min-width: 768px) {
  .aboutHeader {
    composes: aboutHeader;
    padding: 0vh;
  }

  .drawer {
    width: 96%;
  }

  .drawerPaper {
    overflow-x: hidden;
    width: 93%;
    margin-top: 0;
    background: #21232c !important;
  }

  .heroImageContainer {
    composes: heroImageContainer;
    opacity: 1;
  }

  .aboutDescription {
    composes: courseDescription from "../common/styles.module.css";
    padding-right: 5vw;
    padding-left: 0;
  }
}

@media only screen and (min-width: 1224px) {
  .aboutHeader {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 5vh;
    padding: 0vh;
  }

  .drawer {
    width: 96%;
  }

  .drawerPaper {
    overflow-x: hidden;
    width: 93%;
    background: #21232c !important;
    margin-top: 0;
  }

  .heroImageContainer {
    composes: heroImageContainer;
    opacity: 1;
  }
}
