.container {
  min-width: 294px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.subtitle {
}
