.common {
  font-family: "sofia-pro";
  color: #fa4616;
}

.boatSpecs {
  composes: common;
  padding: 10px 0px 10px 0px;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}

.boatSpecContainer {
  composes: courseDescription from "../common/styles.module.css";
  margin-bottom: 50px;
}

.boatInfoGrid {
  display: flex;
  align-Items: left;
  flex-direction: column;
  margin-top: 52px;
}

@media only screen and (min-width: 320px) {
  .boatInfoGrid {
    composes: boatInfoGrid;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .boatInfoGrid {
    composes: boatInfoGrid;
    justify-content: start;
  }
}

@media only screen and (min-width: 1224px) {
  /* .boatInfoGrid {
    display: flex;
    align-Items: center;
    margin-top: 52px;
    justify-content: start;
  } */
}

