.drawerContainer {
  display: flex;
  flex-direction: row;
  padding: 40px calc(40px + 2vw);
  flex: 1 1 auto;
}

.navContainer {
  color: #ffffff;
  max-width: 40em;
  flex: 1 1 40em;
}

.customLink {
  color: #fff;
  text-decoration: none;
}

.navBody {
  display: flex;
  flex-wrap: wrap;
}

.multilineText {
  white-space: pre-line;
}
.text {
  font-size: 23px;
  line-height: 27px;
  text-align: right;
  font-family: "sofia-pro";
  font-weight: bold;
}
.screenreader {
  /* https://stackoverflow.com/questions/26032089 */
  position: absolute !important; /* Outside the DOM flow */
  height: 1px;
  width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.brandPhraseContainer {
  padding: 40px 0 0 40px;
  color: #ffffff;
  text-align: right;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
}

.brandPhrase {
  composes: multilineText;
  font-size: 80px;
  line-height: 81px;
  font-family: "heavitasregular";
  margin: 0;
}
.brandPhraseSeparator {
  margin: 2em 0;
}

.contactDetails {
  flex: 1 0 auto;
  margin-left: auto;
  padding-left: 20px;
}

.contactDetailLabel {
  composes: screenreader;
}

.contactDetail {
  composes: text;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.address {
  composes: customLink;
  composes: multilineText;
  margin-bottom: 3em;
}

.addressContainer {
  margin-bottom: 3em;
}

.emailLink {
  composes: customLink;
}

.socialLinks {
  margin-top: 3em;
  font-family: "sofia-pro";
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.socialLink {
  composes: customLink;
  display: block;
  width: 10em;
  padding: 0.25em;
  width: 100%;
  max-width: 17em;
  background: no-repeat;
  background-position: center right;
  background-size: 15px 12px;
  background-origin: content-box;
  background-image: url("data:image/svg+xml;base64,IDxzdmcKICAgICAgd2lkdGg9IjM0IgogICAgICBoZWlnaHQ9IjU1IgogICAgICB2aWV3Qm94PSIwIDAgMzQgNTUiCiAgICAgIGZpbGw9Im5vbmUiCiAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgID4KICAgICAgPHBhdGgKICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiCiAgICAgICAgY2xpcC1ydWxlPSJldmVub2RkIgogICAgICAgIGQ9Ik0wIDU1SDM0VjBMMCA1NVoiCiAgICAgICAgZmlsbD0id2hpdGUiCiAgICAgIC8+CiAgICA8L3N2Zz4K");
}

.nav {
  flex: 1 0 auto;
}

.navlist {
  list-style: none;
  margin-left: 0;
  margin-bottom: 1.5em;
  color: #ffffff;
}

.navlink,
.navlink:hover {
  font-family: heavitasregular;
  margin-bottom: 1.5em;
  color: inherit;
  text-decoration-line: none;
}

@media screen and (max-width: 600px) {
  .navlink {
    font-family: heavitasregular;
    line-height: 23px;
    font-size: 23px;
    margin-bottom: 1.5em;
  }
}

.navlink:hover {
  cursor: pointer;
}

.navhref {
  text-decoration: none;
  color: #ffffff;
}

.backButtonContainer {
  margin-bottom: 25px;
}

.backButtonContainer .backButton:hover {
  /* Override MUI button hover */
  background: transparent;
}

.logo {
  margin-bottom: 70px;
}

.drawerPaper {
  overflow-x: hidden;
  background: #ff3302 !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

@media only screen and (min-width: 320px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 5%;
    width: 100%;
  }

  .navlink {
    font-family: heavitasregular;
    line-height: 23px;
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 97%;
  }

  .navlink {
    line-height: 30px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 1224px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 97%;
  }

  .navlink {
    line-height: 30px;
    font-size: 30px;
  }
}
