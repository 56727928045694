.jumboText {
  font-family: "heavitasregular";
  white-space: nowrap;
  color: #ffffff;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
}

.homeBannerContainer {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  display: block;
  width: 100%;
}

.homeBannerContainer:first-child {
  position: relative;    
}

.bannerItem {}

.fadeInFromTop {
  opacity: 0;
  animation-name: fadeIn;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 1s;
}

.container {
  overflow: hidden;
}

.containerPadding {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 50px;
}

.homeBanner {
  position: relative;
  margin-bottom: 3vh;
  width: 100%;
}

.textTitle {
  composes: multilineText from "../common/styles.module.css";
  font-family: "heavitasregular";
  color: #ffffff;
  margin-bottom: 20px;
}

.textDescription {
  composes: multilineText from "../common/styles.module.css";
  color: #ffffff;
  margin-bottom: 30px;
}

.homeList {
  text-align: right;
  width: 90%;
}

.homeList,
.homeList > li {
  list-style: none;
  font-family: "heavitasregular";

  color: #ffffff;
  cursor: pointer;
}

.fadeInFromTop {
  opacity: 0;
  animation-name: fadeIn;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 1s;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}


@media only screen and (min-width: 320px) {

  .jumboText {
    font-size: 40px;
    line-height: 40px;
  }

  .homeList,
  .homeList > li {
    font-size: 5vw;
    line-height: 5vw;
  }

  .textContainer {
    margin-top: 36px;
    margin-bottom: 5vh;
  }

  .textTitle {
    font-size: 18px;
    line-height: 18px;
  }

  .textDescription {
    font-size: 18px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 768px) {
  .homeBanner {
    position: relative;
    margin-bottom: 50px;
  }

  .jumboText {
    font-size: 80px;
    line-height: 81px;
  }

  .homeList,
  .homeList > li {
    font-size: 20px;
    line-height: 20px;
  }

  .textContainer {
    margin-top: 0px;
    margin-bottom: 5vh;
  }

  .textTitle {
    font-size: 22px;
    line-height: 22px;
  }

  .textDescription {
    font-size: 18px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 1224px) {
  .container {
    box-sizing: border-box;
  }

  .jumboText {
    font-size: 120px;
    line-height: 121px;
  }

  .homeList,
  .homeList > li {
    font-size: 20px;
    line-height: 20px;
  }

  .textContainer {
    margin-top: 72px;
    composes: containerPadding;
    padding-top: 0;
  }

  .textTitle {
    font-size: 22px;
    line-height: 22px;
  }

  .textDescription {
    font-size: 22px;
    line-height: 26px;
  }
}

.homeList > li {
  margin-bottom: 20px;
}

.bulletPoint {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.bulletPointHidden {
  width: 15px;
  height: 15px;
  display: none;
}

.buttonContainer {
  margin-bottom: 75px !important;
}

.bigW {
  width: 100vh;
  position: relative;
}

.bigWImg {
  float: right;
  position: absolute;
}

.bigWImgIpad {
  composes: bigWImg;
  right: -6vh;
}

.bigWImgLarge {
  composes: bigWImg;
  right: -8vh;
}

.bigWImgMobile {}

/* iPad Pro Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .bigWImgLarge {
    composes: bigWImg;
    right: -6vh;
  }
}
