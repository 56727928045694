body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

address {
  font-style: initial;
}

body:after {
  content: url("./images/AboutPagePhoto1.jpg")
    , url("./images/AboutTheCrewPhoto3.jpg")
    , url("./images/CarlAndPenny.jpg")
    , url("./images/ChartersChartersPhoto2.jpg")
    , url("./images/ChartersChartersPhoto3.jpg")
    , url("./images/ChartersChartersPhoto4.jpg")
    , url("./images/CoursesMainPhoto2.jpg")
    , url("./images/EmotionalRescue.jpg")
    , url("./images/EndlessSummer.jpg")
    , url("./images/GroupSailing.jpg")
    , url("./images/Lessons.jpg")
    , url("./images/Menu-Boats.jpg")
    , url("./images/PrivateLessonsMainPhoto2.jpg")
    , url("./images/PrivateLessonsMainPhoto3.jpg")
    , url("./images/SailingBoat.jpg")
    , url("./images/StockLandScape.jpg")
    , url("./images/SunHarbourBridge.jpg")
    , url("./images/TheBoatsPagePhoto1.jpg");
  display: none;
}