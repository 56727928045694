@media screen and (max-width: 600px) {
  .courseButtonGrid {
    margin-top: 70px;
    width: 100%;
    position: absolute;
  }
}

.courseButton {
  margin-right: 20px;
}

.practicalLessonsGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.practicalLessonsButtonGrid {
  composes: practicalLessonsGrid;
}

.trainingInfo {
  margin-top: 40px;
}

.topicCovers {
  margin-top: "118px";
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: red;
  position: absolute;
}

.drawer {
  flex-shrink: 0;
}

.drawerPaper {
  overflow-x: hidden;
  background: #efeee8;
}

@media only screen and (min-width: 320px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }

  .practicalLessonsGrid {
    composes: practicalLessonsGrid;
    margin-bottom: 5vh !important;
  }

  .practicalLessonsButtonGrid {
    composes: practicalLessonsButtonGrid;
    margin-top: 0 !important;
  }

  .trainingInfo {
    margin-top: 40px;
    padding-left: 3vw;
  }

  .topicCovers {
    margin-top: 10vh !important;
    padding-left: 3vw;
  }

  .courseButton {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .practicalLessonsGrid {
    composes: practicalLessonsGrid;
    margin-bottom: 5vh !important;
  }

  .practicalLessonsButtonGrid {
    composes: practicalLessonsButtonGrid;
    justify-content: flex-end;
    margin-right: 20px !important;
    margin-top: 5vh !important;
  }
  .trainingInfo {
    margin-top: 40px;
    padding-left: 0;
  }

  .topicCovers {
    margin-top: 80px !important;
    padding-left: 0;
  }
}

@media only screen and (min-width: 1224px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .practicalLessonsGrid {
    composes: practicalLessonsGrid;
    margin-right: 0px !important;
  }
  .practicalLessonsButtonGrid {
    composes: practicalLessonsButtonGrid;
    margin-top: 0 !important;
  }
  .trainingInfo {
    margin-top: 40px;
    padding-left: 0;
  }

  .topicCovers {
    margin-top: 118px;
    padding-left: 0;
  }

  .courseButton {
    margin-right: 20px;
  }
}
