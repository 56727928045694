.drawerPaper {
  overflow-x: hidden;
  background: #efeee8 !important;
  display: flex;
  flex-direction: column;
}

.charterPhotoOne {
  width: 773px;
  height: 515px;
}

.contactWrap {
  margin-top: 50px;
  max-width: 600px;
}


@media only screen and (max-width: 1224px) {
  .getInTouch {
    margin-bottom: 20px;
  }

  .contactWrap a {
    display: block;
  }

  .contactWrap a:first-child {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1224px) {
  .contactWrap {
    display: flex;
    justify-content: space-between;
  }
}

.contact {
  font-family: "sofia-pro";
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
  color: #fa4616;
}

.charterPhotoTwo {
  max-width: 100%;
  max-height: 500px;
  margin-top: 20px;
}

.charterPhotoThree {
  max-width: 582px;
  max-height: 435px;
}

@media only screen and (min-width: 320px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }

  .navlink {
    font-family: heavitasregular;
    line-height: 23px;
    font-size: 23px;
  }
  .charterPhotoTwo {
    composes: charterPhotoTwo;
    width: 100%;
  }
  .charterPhotoThree {
    height: 435px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }

  .navlink {
    line-height: 30px;
    font-size: 30px;
  }

  .charterPhotoTwo {
    composes: charterPhotoTwo;
    max-width: 100%;
  }
  .charterPhotoThree {
    composes: charterPhotoThree;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1224px) {
  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }

  .navlink {
    line-height: 30px;
    font-size: 30px;
  }
}
