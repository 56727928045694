@import url(https://p.typekit.net/p.css?s=1&k=ove5dww&ht=tk&f=24539.24547&a=7481&app=typekit&e=css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

address {
  font-style: normal;
  font-style: initial;
}

body:after {
  content: url(https://www.whitingsailing.com/static/media/AboutPagePhoto1.ec8a9cb2.jpg)
    , url(https://www.whitingsailing.com/static/media/AboutTheCrewPhoto3.c99b7047.jpg)
    , url(https://www.whitingsailing.com/static/media/CarlAndPenny.126a5128.jpg)
    , url(https://www.whitingsailing.com/static/media/ChartersChartersPhoto2.af36ddec.jpg)
    , url(https://www.whitingsailing.com/static/media/ChartersChartersPhoto3.fff744e6.jpg)
    , url(https://www.whitingsailing.com/static/media/ChartersChartersPhoto4.78cf2ce5.jpg)
    , url(https://www.whitingsailing.com/static/media/CoursesMainPhoto2.ae23fc05.jpg)
    , url(https://www.whitingsailing.com/static/media/EmotionalRescue.61e7b309.jpg)
    , url(https://www.whitingsailing.com/static/media/EndlessSummer.87f3d20c.jpg)
    , url(https://www.whitingsailing.com/static/media/GroupSailing.e3f0567f.jpg)
    , url(https://www.whitingsailing.com/static/media/Lessons.3b215c80.jpg)
    , url(https://www.whitingsailing.com/static/media/Menu-Boats.43b68c47.jpg)
    , url(https://www.whitingsailing.com/static/media/PrivateLessonsMainPhoto2.229c630d.jpg)
    , url(https://www.whitingsailing.com/static/media/PrivateLessonsMainPhoto3.1dba51e4.jpg)
    , url(https://www.whitingsailing.com/static/media/SailingBoat.92c20f15.jpg)
    , url(https://www.whitingsailing.com/static/media/StockLandScape.b1ba3e54.jpg)
    , url(https://www.whitingsailing.com/static/media/SunHarbourBridge.3dafbc13.jpg)
    , url(https://www.whitingsailing.com/static/media/TheBoatsPagePhoto1.5fdc9904.jpg);
  display: none;
}
@font-face {
  font-family: "sofia-pro";
  src: url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "sofia-pro";
  src: url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

.tk-sofia-pro {
  font-family: "sofia-pro", sans-serif;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overflow-y: scroll;
}

@font-face {
  font-family: "heavitasregular";
  src: local("heavitasregular"),
    url(https://www.whitingsailing.com/static/media/heavitas-webfont.4b7be84a.ttf) format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(https://www.whitingsailing.com/static/media/HomeBackgroundExperience.73b69806.jpg),
    url(https://www.whitingsailing.com/static/media/HomeBackgroundLearnToSail.5a2bef88.jpg),
    url(https://www.whitingsailing.com/static/media/HomeBackgroundPrivateLessons.09496b4d.jpg),
    url(https://www.whitingsailing.com/static/media/Menu-Boats.43b68c47.jpg);
  background-size: cover;
  font-family: "heavitasregular";
}

html,
body,
#___gatsby {
  max-width: 100%;
  overflow-x: hidden;
  height: 100%;
}

div[role="group"][tabindex] {
  height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}
video {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%; 
  min-height: 100%; 
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  object-fit: cover;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: heavitasregular, heavitasregular;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: 700;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

html {
  font: 112.5%/1.45em "heavitasregular";
  box-sizing: border-box;
  overflow-y: hidden;
}

* {
  box-sizing: inherit;
}

*:before {
  box-sizing: inherit;
}

*:after {
  box-sizing: inherit;
}

body {
  color: hsla(0, 0%, 0%, 0.8);
  font-family: georgia, serif;
  font-weight: normal;
  word-wrap: break-word;
  -webkit-font-kerning: normal;
          font-kerning: normal;
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt", "kern";
}

img {
  /* max-width: 100%; */
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2.25rem;
  line-height: 1.1;
}

h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.62671rem;
  line-height: 1.1;
}

h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.38316rem;
  line-height: 1.1;
}

h4 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  line-height: 1.1;
}

h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.85028rem;
  line-height: 1.1;
}

h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1;
}

hgroup {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

ul {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none;
}

ol {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none;
}

dl {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

dd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

pre {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 1.45rem;
  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
  padding: 1.45rem;
}

table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-size: 1rem;
  line-height: 1.45rem;
  border-collapse: collapse;
  width: 100%;
}

fieldset {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

blockquote {
  margin-left: 1.45rem;
  margin-right: 1.45rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

noscript {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

iframe {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

hr {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}

address {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

dt {
  font-weight: bold;
}

th {
  font-weight: bold;
}

li {
  margin-bottom: calc(1.45rem / 2);
}

ol li {
  padding-left: 0;
}

ul li {
  padding-left: 0;
}

li > ol {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}

li > ul {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}

blockquote *:last-child {
  margin-bottom: 0;
}

li *:last-child {
  margin-bottom: 0;
}

p *:last-child {
  margin-bottom: 0;
}

li > p {
  margin-bottom: calc(1.45rem / 2);
}

code {
  font-size: 0.85rem;
  line-height: 1.45rem;
}

kbd {
  font-size: 0.85rem;
  line-height: 1.45rem;
}

samp {
  font-size: 0.85rem;
  line-height: 1.45rem;
}

abbr {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}

acronym {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}

abbr[title] {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
  text-decoration: none;
}

thead {
  text-align: left;
}

td,
th {
  text-align: left;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  padding-left: 0.96667rem;
  padding-right: 0.96667rem;
  padding-top: 0.725rem;
  padding-bottom: calc(0.725rem - 1px);
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

tt,
code {
  background-color: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
    "Liberation Mono", Menlo, Courier, monospace;
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

pre code {
  background: none;
  line-height: 1.42;
}

code:before,
code:after,
tt:before,
tt:after {
  letter-spacing: -0.2em;
  content: " ";
}

pre code:before,
pre code:after,
pre tt:before,
pre tt:after {
  content: "";
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}

menu {
  width: 35px;
  height: 5px;
  background-color: #ff3302;
  margin: 6px 0;
}

.logo {
  position: absolute;
  width: 281px;
  height: 89px;
  left: 146px;
  top: 89px;
}

.home-title {
  position: absolute;
  width: 798px;
  height: 121px;
  left: 562px;
  top: 198px;

  font-family: "heavitasregular";
  font-size: 120px;
  line-height: 121px;
  /* identical to box height */

  color: #ffffff;
}

.MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiDrawer-paper {
  /* overflow-y: hidden !important; */
}

.Drawer_drawerContainer__18-Yt {
  display: flex;
  flex-direction: row;
  padding: 40px calc(40px + 2vw);
  flex: 1 1 auto;
}

.Drawer_navContainer__2uV1G {
  color: #ffffff;
  max-width: 40em;
  flex: 1 1 40em;
}

.Drawer_customLink__qAbCd {
  color: #fff;
  text-decoration: none;
}

.Drawer_navBody__1cOTc {
  display: flex;
  flex-wrap: wrap;
}

.Drawer_multilineText__1oKFZ {
  white-space: pre-line;
}
.Drawer_text__22P9h {
  font-size: 23px;
  line-height: 27px;
  text-align: right;
  font-family: "sofia-pro";
  font-weight: bold;
}
.Drawer_screenreader__4T6H0 {
  /* https://stackoverflow.com/questions/26032089 */
  position: absolute !important; /* Outside the DOM flow */
  height: 1px;
  width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.Drawer_brandPhraseContainer__1UPAl {
  padding: 40px 0 0 40px;
  color: #ffffff;
  text-align: right;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
}

.Drawer_brandPhrase__LR6p3 {
  font-size: 80px;
  line-height: 81px;
  font-family: "heavitasregular";
  margin: 0;
}
.Drawer_brandPhraseSeparator__2ZFZf {
  margin: 2em 0;
}

.Drawer_contactDetails__bt5rU {
  flex: 1 0 auto;
  margin-left: auto;
  padding-left: 20px;
}

.Drawer_contactDetailLabel__3C118 {
}

.Drawer_contactDetail__2y2_6 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.Drawer_address__Vp_oz {
  margin-bottom: 3em;
}

.Drawer_addressContainer__tJ6Qn {
  margin-bottom: 3em;
}

.Drawer_emailLink__21dB6 {
}

.Drawer_socialLinks___2U7n {
  margin-top: 3em;
  font-family: "sofia-pro";
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.Drawer_socialLink__dJXOj {
  display: block;
  width: 10em;
  padding: 0.25em;
  width: 100%;
  max-width: 17em;
  background: no-repeat;
  background-position: center right;
  background-size: 15px 12px;
  background-origin: content-box;
  background-image: url("data:image/svg+xml;base64,IDxzdmcKICAgICAgd2lkdGg9IjM0IgogICAgICBoZWlnaHQ9IjU1IgogICAgICB2aWV3Qm94PSIwIDAgMzQgNTUiCiAgICAgIGZpbGw9Im5vbmUiCiAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgID4KICAgICAgPHBhdGgKICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiCiAgICAgICAgY2xpcC1ydWxlPSJldmVub2RkIgogICAgICAgIGQ9Ik0wIDU1SDM0VjBMMCA1NVoiCiAgICAgICAgZmlsbD0id2hpdGUiCiAgICAgIC8+CiAgICA8L3N2Zz4K");
}

.Drawer_nav__28EgK {
  flex: 1 0 auto;
}

.Drawer_navlist__2umB_ {
  list-style: none;
  margin-left: 0;
  margin-bottom: 1.5em;
  color: #ffffff;
}

.Drawer_navlink__hTKnf,
.Drawer_navlink__hTKnf:hover {
  font-family: heavitasregular;
  margin-bottom: 1.5em;
  color: inherit;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

@media screen and (max-width: 600px) {
  .Drawer_navlink__hTKnf {
    font-family: heavitasregular;
    line-height: 23px;
    font-size: 23px;
    margin-bottom: 1.5em;
  }
}

.Drawer_navlink__hTKnf:hover {
  cursor: pointer;
}

.Drawer_navhref__1ljLV {
  text-decoration: none;
  color: #ffffff;
}

.Drawer_backButtonContainer__1IPRi {
  margin-bottom: 25px;
}

.Drawer_backButtonContainer__1IPRi .Drawer_backButton___V6ft:hover {
  /* Override MUI button hover */
  background: transparent;
}

.Drawer_logo__2I_jp {
  margin-bottom: 70px;
}

.Drawer_drawerPaper__2dq6Z {
  overflow-x: hidden;
  background: #ff3302 !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

@media only screen and (min-width: 320px) {
  .Drawer_drawerPaper__2dq6Z {
    overflow-x: hidden;
    margin-top: 5%;
    width: 100%;
  }

  .Drawer_navlink__hTKnf {
    font-family: heavitasregular;
    line-height: 23px;
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) {
  .Drawer_drawerPaper__2dq6Z {
    overflow-x: hidden;
    margin-top: 0;
    width: 97%;
  }

  .Drawer_navlink__hTKnf {
    line-height: 30px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 1224px) {
  .Drawer_drawerPaper__2dq6Z {
    overflow-x: hidden;
    margin-top: 0;
    width: 97%;
  }

  .Drawer_navlink__hTKnf {
    line-height: 30px;
    font-size: 30px;
  }
}

.styles_common__2M8ma {
  font-family: "sofia-pro";
  color: #fa4616;
}

.styles_subMenuTitle__c1m1B {
  color: #fa4616;
  font-family: heavitasregular !important;
  font-size: 62px !important;
  line-height: 63px !important;
  font-weight: normal !important;
  z-index: 1;
}

.styles_multilineText__16hrX {
  white-space: pre-line;
  font-size: 32px;
  line-height: 37px;
  font-weight: bold;
  margin-right: 1em;
  font-weight: 300;
}

.styles_heroText__hNyzv {
}

.styles_courseDescription__2j4Yo {
  font-size: 16px;
  line-height: 19px;
  font-weight: 300;
  margin-bottom: 20px;
}

.styles_dayDescription__1unLS {
  margin-top: 1.5em;
  line-height: 19px;
}

.styles_topicsCoveredHeader__3VzK3 {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
}

.styles_topicsCoveredList__2HKJo {
  white-space: pre-line;
  font-size: 16px;
  line-height: 19px;
  margin-top: 1em;
}

.styles_lessonHeader__3ZJtF {
  font-size: 27px;
  line-height: 32px;
  font-weight: bold;
  text-transform: uppercase;
}

.styles_lessonDescription__Shczf {
  white-space: pre-line;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  margin-top: 20px;
}

.styles_compass__3xuOL {
  margin-top: 5vh;
  width: 250px;
  height: 250px;
  position: absolute;
  margin-top: 13vh;
}

.styles_pageTitle__37IMr {
  font-family: "heavitasregular";
  color: #fa4616;
}

@media only screen and (min-width: 320px) {
  .styles_pageTitle__37IMr {
    font-size: 42px;
    line-height: 42px;
  }

  .styles_heroText__hNyzv {
    font-size: 26px;
    line-height: 30px;
  }

  .styles_courseDescription__2j4Yo {
  }

  .styles_compass__3xuOL {
    position: relative;
    margin-top: 4vh;
  }

  .styles_subMenuTitle__c1m1B {
    font-size: 42px !important;
    line-height: 42px !important;
    font-weight: normal !important;
  }

  .styles_lessonHeader__3ZJtF {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 768px) {
  .styles_pageTitle__37IMr {
    font-size: 62px;
    line-height: 63px;
  }

  .styles_heroText__hNyzv {
    font-size: 26px;
    line-height: 30px;
  }
  .styles_courseDescription__2j4Yo {
  }

  .styles_compass__3xuOL {
    position: absolute;
    margin-top: 14vh;
  }

  .styles_subMenuTitle__c1m1B {
    font-size: 62px !important;
    line-height: 63px !important;
  }
  .styles_lessonHeader__3ZJtF {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 1224px) {
  .styles_pageTitle__37IMr {
    font-size: 62px;
    line-height: 63px;
  }

  .styles_heroText__hNyzv {
    font-size: 32px;
    line-height: 37px;
  }
  .styles_courseDescription__2j4Yo {
  }

  .styles_compass__3xuOL {
    position: absolute;
  }

  .styles_subMenuTitle__c1m1B {
    font-size: 62px !important;
    line-height: 63px !important;
  }

  .styles_lessonHeader__3ZJtF {
    font-size: 27px;
    line-height: 32px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .styles_customLink__1ZHwM {
    color: #fff;
    text-decoration: none;
  }

  .styles_socialLink__hbqBZ {
    display: block;
    width: 10em;
    padding: 0.25em;
    width: 100%;
    max-width: 17em;
    background: no-repeat;
    background-position: center right;
    background-size: 15px 12px;
    background-origin: content-box;
    background-image: url("data:image/svg+xml;base64,IDxzdmcKICAgICAgd2lkdGg9IjM0IgogICAgICBoZWlnaHQ9IjU1IgogICAgICB2aWV3Qm94PSIwIDAgMzQgNTUiCiAgICAgIGZpbGw9Im5vbmUiCiAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgID4KICAgICAgPHBhdGgKICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiCiAgICAgICAgY2xpcC1ydWxlPSJldmVub2RkIgogICAgICAgIGQ9Ik0wIDU1SDM0VjBMMCA1NVoiCiAgICAgICAgZmlsbD0id2hpdGUiCiAgICAgIC8+CiAgICA8L3N2Zz4K");
  }

  .styles_socialLinks__2pP-Y {
    margin-top: 3em;
    font-family: "sofia-pro";
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }
}

h1 {
  font-family: "heavitasregular";
  font-size: 62px;
  line-height: 62px;
  color: #fa4616;
}

.styles_address__1im4g {
  font-family: "sofia-pro";
  font-size: 23px;
  line-height: 27px;
  margin-bottom: 30px;
  color: #fa4616;
  text-align: right;
  text-decoration: none;
}

.styles_contact__3eqkC {
  font-family: "sofia-pro";
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
  color: #fa4616;
  text-align: right;
}

.styles_contactGrid__X7Nwk {
  margin-top: 0px !important;
}

.styles_drawer__2BDYW {
  flex-shrink: 0;
}

.styles_drawerPaper__pJJrM {
  overflow-x: hidden;
  width: 96%;
  background: #efeee8;
}

.styles_formDesc__3BxI9 {
  font-family: "sofia-pro";
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-top: 50px;
  color: #fa4616;
}

.styles_inputGroup__2VFec {
  font-family: "sofia-pro";
  margin-bottom: 20px;
}

.styles_textField__2Y0wJ {
  width: 424px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #fa4616;
  background-color: inherit;
}

.styles_textField__2Y0wJ:focus,
.styles_formButton__nrXzW:focus {
  outline: none;
}

.styles_textFieldLabel__lrk9o {
  color: #fa4616;
  font-size: 12px;
  line-height: 14px;
}

.styles_formButton__nrXzW {
  margin-top: 20px;
  border: 0;
  font-family: "sofia-pro";
  font-size: 18px;
  line-height: 21px;
  background-color: #fa4616;
  color: #ffffff;
  text-transform: uppercase;
  width: 191px;
  height: 38px;
  float: right;
}
.styles_formButton__nrXzW:hover {
  background: #ff6347;
  cursor: pointer;
}

.styles_socialLinks__3ISW3 {
  margin-top: 0px !important;
}

.styles_formButtonGrid__AxoMw {
  width: 424px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 992px) {
  .styles_inputGroup__2VFec {
    font-family: "sofia-pro";
    margin-bottom: 5px;
  }

  .styles_socialLinks__3ISW3 {
    margin-top: 3em;
    font-family: "sofia-pro";
    font-weight: bold;
    display: flex;
    flex-direction: row;
    width: 14em !important;
  }
}

@media screen and (max-width: 600px) {
  .styles_formButtonGrid__AxoMw {
    justify-content: center;
    width: 80vw;
  }

  .styles_formButton__nrXzW {
    width: 80vw;
  }

  .styles_textField__2Y0wJ {
    width: 80vw;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #fa4616;
    background-color: inherit;
  }

  .styles_inputGroup__2VFec {
    font-family: "sofia-pro";
    margin-bottom: 5px;
  }

  .styles_contactGrid__X7Nwk {
    margin-top: 50px !important;
    padding-right: 10vw;
  }

  .styles_socialLinks__3ISW3 {
    margin-top: 3em;
    font-family: "sofia-pro";
    font-weight: bold;
    display: flex;
    flex-direction: row;
    width: 100% !important;
    margin-top: 40px !important;
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

/* iPad Pro Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .styles_socialLinks__3ISW3 {
    margin-top: 3em;
    font-family: "sofia-pro";
    font-weight: bold;
    display: flex;
    flex-direction: row;
    width: 80% !important;
  }
}

.styles_socialLink__horbg {
  background-image: url(https://www.whitingsailing.com/static/media/TriangleRed.938bc589.svg) !important;
  background-repeat: no-repeat !important;
  color: #fa4616;
  font-size: 16px;
  line-height: 19px;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.styles_socialLink__horbg:hover {
  color: #fa4616;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

@media only screen and (min-width: 320px) {
  .styles_drawerPaper__pJJrM {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }
  .styles_socialLink__horbg {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .styles_drawerPaper__pJJrM {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .styles_socialLink__horbg {
    width: 100%;
  }
}

@media only screen and (min-width: 1224px) {
  .styles_drawerPaper__pJJrM {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .styles_socialLink__horbg {
    width: 60%;
  }
}

.styles_navDrawer__2vJ3n,
.styles_navPage__3i9A1 {
  background: #ff3302 !important;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.styles_navPage__3i9A1 {
  background: #efeee8 !important;
}

.styles_navPageContainer__29jPE {
  margin-top: 4em;
}

.styles_drawerPaper__2Lp1V {
  overflow-x: hidden;
  background-color: #efefe9 !important;
}

@media only screen and (min-width: 320px) {
  .styles_navDrawer__2vJ3n {
    margin-top: 5%;
    width: 100%;
  }

  .styles_navPage__3i9A1 {
    margin-top: 10%;
    width: 100%;
  }

  .styles_navLink__FrHxa {
    line-height: 23px;
    font-size: 23px;
  }

  .styles_drawerPaper__2Lp1V {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .styles_navDrawer__2vJ3n {
    margin-top: 0;
    width: 95%;
  }

  .styles_navPage__3i9A1 {
    margin-top: 0;
    width: 90%;
  }

  .styles_navLink__FrHxa {
    line-height: 30px;
    font-size: 30px;
  }

  .styles_drawerPaper__2Lp1V {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }
}

@media only screen and (min-width: 1224px) {
  .styles_navDrawer__2vJ3n {
    margin-top: 0;
    width: 95%;
  }

  .styles_navPage__3i9A1 {
    margin-top: 0;
    width: 90%;
  }

  .styles_navLink__FrHxa {
    line-height: 30px;
    font-size: 30px;
  }

  .styles_drawerPaper__2Lp1V {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }
}

.PrimaryButton_container__33hdf {
  min-width: 294px;
}

.PrimaryButton_title__3iP1J {
  font-weight: bold;
  font-size: 16px;
}

.PrimaryButton_subtitle__Tdhg8 {
}

.privateLessons_pricingContainer__1Qy9_ {
  border: 2px solid #ff3302;
  margin-bottom: 20px;
  padding: 20px;
  font-family: "heavitasregular";
  color: #ff3302;
  width: 500px;
}

.privateLessons_seaChangeLogoContainer__HJpTr {
  display: flex;
  justify-content: center;
}

.privateLessons_descContainer__1jdv- {
  font-family: "sofia-pro";
  color: #ff3302;
}

.privateLessons_pricingText__3JTL6 {
  font-size: 25px;
  margin-bottom: 2vh;
}

.privateLessons_inclText__1T68Q {
  font-size: 12px;
}

.privateLessons_costText__3LhUQ {
  float: right;
  clear: right;
}
.privateLessons_lessonText__OOmj3 {
  float: right;
  clear: right;
  margin-bottom: 2vh;
}

@media only screen and (min-width: 320px) {
  .privateLessons_pricingContainer__1Qy9_ {
    max-width: 100%;
    margin: 5vw;
  }

  .privateLessons_drawerPaper__177Op {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }

  .privateLessons_descContainer__1jdv- {
    font-family: "sofia-pro";
    color: #ff3302;
    padding: 5vw;
  }
  .privateLessons_seaChangeLogoContainer__HJpTr {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .privateLessons_pricingContainer__1Qy9_ {
    max-width: 100%;
    margin: 0;
    margin-bottom: 30vh;
  }

  .privateLessons_drawerPaper__177Op {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .privateLessons_descContainer__1jdv- {
    font-family: "sofia-pro";
    color: #ff3302;
    padding: 0;
  }
  .privateLessons_seaChangeLogoContainer__HJpTr {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1224px) {
  .privateLessons_pricingContainer__1Qy9_ {
    max-width: 80%;
    margin: 0;
  }

  .privateLessons_drawerPaper__177Op {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .privateLessons_descContainer__1jdv- {
    font-family: "sofia-pro";
    color: #ff3302;
    padding: 0;
  }
  .privateLessons_seaChangeLogoContainer__HJpTr {
    display: flex;
    justify-content: flex-start;
  }
}

.styles_aboutDescription__2qQ3j {
}

.styles_menuButton__1sCFE {
  float: "right";
}

.styles_hide__2O0D- {
  display: "none";
}

.styles_drawer__Uf5XO {
  flex-shrink: 0;
}

.styles_drawerPaper__1o9Sg {
  overflow-x: hidden;
  background: #21232c !important;
}

.styles_aboutHeader__14_pz {
  color: #fa4616;
  font-family: "heavitasregular";
  font-weight: normal;
  margin-bottom: 5vh;
}

.styles_heroImageContainer__Bx6L4 {
  max-height: 524px;
  width: auto;
  background: #000000;
  margin-bottom: 0;
  float: right;
  opacity: 1;
}

@media only screen and (min-width: 320px) {
  .styles_aboutHeader__14_pz {
    padding-right: 20vw;
    font-size: 30px;
    line-height: 1;
  }

  .styles_drawer__Uf5XO {
    width: 100%;
  }

  .styles_drawerPaper__1o9Sg {
    overflow-x: hidden;
    width: 100%;
    margin-top: 10%;
    background: #21232c !important;
  }

  .styles_heroImageContainer__Bx6L4 {
    opacity: 0.5;
  }

  .styles_aboutDescription__2qQ3j {
  }
}

@media only screen and (min-width: 768px) {
  .styles_aboutHeader__14_pz {
  }

  .styles_drawer__Uf5XO {
    width: 96%;
  }

  .styles_drawerPaper__1o9Sg {
    overflow-x: hidden;
    width: 96%;
    margin-top: 0;
    background: #21232c !important;
  }

  .styles_heroImageContainer__Bx6L4 {
    opacity: 1;
  }

  .styles_aboutDescription__2qQ3j {
  }
}

@media only screen and (min-width: 1224px) {
  .styles_aboutHeader__14_pz {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 5vh;
  }

  .styles_drawer__Uf5XO {
    width: 96%;
  }

  .styles_drawerPaper__1o9Sg {
    overflow-x: hidden;
    width: 96%;
    background: #21232c !important;
    margin-top: 0;
  }

  .styles_heroImageContainer__Bx6L4 {
    opacity: 1;
  }
}

.styles_container__2xR7R {
  width: 100%;
}

.styles_loadingEffect__3s24R {
  background-color: white;
  height: 15px;
  display: block;
  -webkit-animation: styles_reveal__Urp19 3s;
          animation: styles_reveal__Urp19 3s;
}

@-webkit-keyframes styles_reveal__Urp19 {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes styles_reveal__Urp19 {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.styles_jumboText__2-n8Y {
  font-family: "heavitasregular";
  white-space: nowrap;
  color: #ffffff;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
}

.styles_homeBannerContainer__3jWcE {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  display: block;
  width: 100%;
}

.styles_homeBannerContainer__3jWcE:first-child {
  position: relative;    
}

.styles_bannerItem__1Wt1K {}

.styles_fadeInFromTop__3EGsi {
  opacity: 0;
  -webkit-animation-name: styles_fadeIn__1WF8S;
          animation-name: styles_fadeIn__1WF8S;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.styles_container__158qq {
  overflow: hidden;
}

.styles_containerPadding__U72Xs {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 50px;
}

.styles_homeBanner__3VTIu {
  position: relative;
  margin-bottom: 3vh;
  width: 100%;
}

.styles_textTitle__2nYSQ {
  font-family: "heavitasregular";
  color: #ffffff;
  margin-bottom: 20px;
}

.styles_textDescription__3NHgh {
  color: #ffffff;
  margin-bottom: 30px;
}

.styles_homeList__1eWKJ {
  text-align: right;
  width: 90%;
}

.styles_homeList__1eWKJ,
.styles_homeList__1eWKJ > li {
  list-style: none;
  font-family: "heavitasregular";

  color: #ffffff;
  cursor: pointer;
}

.styles_fadeInFromTop__3EGsi {
  opacity: 0;
  -webkit-animation-name: styles_fadeIn__1WF8S;
          animation-name: styles_fadeIn__1WF8S;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes styles_fadeIn__1WF8S {
  0% {
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes styles_fadeIn__1WF8S {
  0% {
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}


@media only screen and (min-width: 320px) {

  .styles_jumboText__2-n8Y {
    font-size: 40px;
    line-height: 40px;
  }

  .styles_homeList__1eWKJ,
  .styles_homeList__1eWKJ > li {
    font-size: 5vw;
    line-height: 5vw;
  }

  .styles_textContainer__3wbEP {
    margin-top: 36px;
    margin-bottom: 5vh;
  }

  .styles_textTitle__2nYSQ {
    font-size: 18px;
    line-height: 18px;
  }

  .styles_textDescription__3NHgh {
    font-size: 18px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 768px) {
  .styles_homeBanner__3VTIu {
    position: relative;
    margin-bottom: 50px;
  }

  .styles_jumboText__2-n8Y {
    font-size: 80px;
    line-height: 81px;
  }

  .styles_homeList__1eWKJ,
  .styles_homeList__1eWKJ > li {
    font-size: 20px;
    line-height: 20px;
  }

  .styles_textContainer__3wbEP {
    margin-top: 0px;
    margin-bottom: 5vh;
  }

  .styles_textTitle__2nYSQ {
    font-size: 22px;
    line-height: 22px;
  }

  .styles_textDescription__3NHgh {
    font-size: 18px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 1224px) {
  .styles_container__158qq {
    box-sizing: border-box;
  }

  .styles_jumboText__2-n8Y {
    font-size: 120px;
    line-height: 121px;
  }

  .styles_homeList__1eWKJ,
  .styles_homeList__1eWKJ > li {
    font-size: 20px;
    line-height: 20px;
  }

  .styles_textContainer__3wbEP {
    margin-top: 72px;
    padding-top: 0;
  }

  .styles_textTitle__2nYSQ {
    font-size: 22px;
    line-height: 22px;
  }

  .styles_textDescription__3NHgh {
    font-size: 22px;
    line-height: 26px;
  }
}

.styles_homeList__1eWKJ > li {
  margin-bottom: 20px;
}

.styles_bulletPoint__2B8X8 {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.styles_bulletPointHidden__3hBNM {
  width: 15px;
  height: 15px;
  display: none;
}

.styles_buttonContainer__ks6iF {
  margin-bottom: 75px !important;
}

.styles_bigW__2K62T {
  width: 100vh;
  position: relative;
}

.styles_bigWImg__1KjqM {
  float: right;
  position: absolute;
}

.styles_bigWImgIpad__3icxh {
  right: -6vh;
}

.styles_bigWImgLarge__21nBT {
  right: -8vh;
}

.styles_bigWImgMobile__2kQWq {}

/* iPad Pro Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .styles_bigWImgLarge__21nBT {
    right: -6vh;
  }
}

.about_aboutDescription__2gxAX {
  padding-right: 5vw;
}

.about_menuButton__3Hm7- {
  float: "right";
}

.about_hide__TUDAl {
  display: "none";
}

.about_drawer__2_m8k {
  flex-shrink: 0;
}

.about_drawerPaper__mOihK {
  overflow-x: hidden;
  background: #21232c !important;
}

.about_aboutHeader__3s9Nf {
  color: #fa4616;
  font-family: "heavitasregular";
  font-weight: normal;
  margin-bottom: 5vh;
}

.about_heroImageContainer__jbLiS {
  max-height: 524px;
  width: auto;
  background: #000000;
  margin-bottom: 0;
  float: right;
  opacity: 1;
}

@media only screen and (min-width: 320px) {
  .about_aboutHeader__3s9Nf {
    padding-right: 20vw;
    font-size: 30px;
    line-height: 1;
    padding-left: 5vw;
  }

  .about_drawer__2_m8k {
    width: 100%;
  }

  .about_drawerPaper__mOihK {
    overflow-x: hidden;
    width: 100%;
    margin-top: 10%;
    background: #21232c !important;
  }

  .about_heroImageContainer__jbLiS {
    opacity: 0.5;
  }

  .about_aboutDescription__2gxAX {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@media only screen and (min-width: 768px) {
  .about_aboutHeader__3s9Nf {
    padding: 0vh;
  }

  .about_drawer__2_m8k {
    width: 96%;
  }

  .about_drawerPaper__mOihK {
    overflow-x: hidden;
    width: 93%;
    margin-top: 0;
    background: #21232c !important;
  }

  .about_heroImageContainer__jbLiS {
    opacity: 1;
  }

  .about_aboutDescription__2gxAX {
    padding-right: 5vw;
    padding-left: 0;
  }
}

@media only screen and (min-width: 1224px) {
  .about_aboutHeader__3s9Nf {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 5vh;
    padding: 0vh;
  }

  .about_drawer__2_m8k {
    width: 96%;
  }

  .about_drawerPaper__mOihK {
    overflow-x: hidden;
    width: 93%;
    background: #21232c !important;
    margin-top: 0;
  }

  .about_heroImageContainer__jbLiS {
    opacity: 1;
  }
}

.boat-info_common__1c5k- {
  font-family: "sofia-pro";
  color: #fa4616;
}

.boat-info_boatSpecs__2QtD8 {
  padding: 10px 0px 10px 0px;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}

.boat-info_boatSpecContainer__3-6uW {
  margin-bottom: 50px;
}

.boat-info_boatInfoGrid__c_kQm {
  display: flex;
  align-Items: left;
  flex-direction: column;
  margin-top: 52px;
}

@media only screen and (min-width: 320px) {
  .boat-info_boatInfoGrid__c_kQm {
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .boat-info_boatInfoGrid__c_kQm {
    justify-content: start;
  }
}

@media only screen and (min-width: 1224px) {
  /* .boatInfoGrid {
    display: flex;
    align-Items: center;
    margin-top: 52px;
    justify-content: start;
  } */
}


.coming-soon_drawerPaper__3DNjA {
  overflow-x: hidden;
  background: #efeee8 !important;
  display: flex;
  flex-direction: column;
}

.coming-soon_charterPhotoOne__1DKn_ {
  width: 773px;
  height: 515px;
}

.coming-soon_contactWrap__1FvGm {
  margin-top: 50px;
  max-width: 600px;
}


@media only screen and (max-width: 1224px) {
  .coming-soon_getInTouch__1OVpH {
    margin-bottom: 20px;
  }

  .coming-soon_contactWrap__1FvGm a {
    display: block;
  }

  .coming-soon_contactWrap__1FvGm a:first-child {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1224px) {
  .coming-soon_contactWrap__1FvGm {
    display: flex;
    justify-content: space-between;
  }
}

.coming-soon_contact__3NH0v {
  font-family: "sofia-pro";
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
  color: #fa4616;
}

.coming-soon_charterPhotoTwo__1c20s {
  max-width: 100%;
  max-height: 500px;
  margin-top: 20px;
}

.coming-soon_charterPhotoThree__1V1hS {
  max-width: 582px;
  max-height: 435px;
}

@media only screen and (min-width: 320px) {
  .coming-soon_drawerPaper__3DNjA {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }

  .coming-soon_navlink__1qnAN {
    font-family: heavitasregular;
    line-height: 23px;
    font-size: 23px;
  }
  .coming-soon_charterPhotoTwo__1c20s {
    width: 100%;
  }
  .coming-soon_charterPhotoThree__1V1hS {
    height: 435px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .coming-soon_drawerPaper__3DNjA {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }

  .coming-soon_navlink__1qnAN {
    line-height: 30px;
    font-size: 30px;
  }

  .coming-soon_charterPhotoTwo__1c20s {
    max-width: 100%;
  }
  .coming-soon_charterPhotoThree__1V1hS {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1224px) {
  .coming-soon_drawerPaper__3DNjA {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }

  .coming-soon_navlink__1qnAN {
    line-height: 30px;
    font-size: 30px;
  }
}

.charters_drawerPaper__2Nts8 {
  overflow-x: hidden;
  background: #efeee8 !important;
  display: flex;
  flex-direction: column;
}

.charters_charterPhotoOne__2BoO9 {
  width: 773px;
  height: 515px;
}

.charters_charterPhotoTwo__2SkWf {
  max-width: 100%;
  max-height: 500px;
  margin-top: 20px;
}

.charters_charterPhotoThree__3nrCz {
  max-width: 582px;
  max-height: 435px;
}

@media only screen and (min-width: 320px) {
  .charters_drawerPaper__2Nts8 {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }

  .charters_navlink__1vIU7 {
    font-family: heavitasregular;
    line-height: 23px;
    font-size: 23px;
  }
  .charters_charterPhotoTwo__2SkWf {
    width: 100%;
  }
  .charters_charterPhotoThree__3nrCz {
    height: 435px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .charters_drawerPaper__2Nts8 {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }

  .charters_navlink__1vIU7 {
    line-height: 30px;
    font-size: 30px;
  }

  .charters_charterPhotoTwo__2SkWf {
    max-width: 100%;
  }
  .charters_charterPhotoThree__3nrCz {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1224px) {
  .charters_drawerPaper__2Nts8 {
    overflow-x: hidden;
    margin-top: 0;
    width: 93%;
  }

  .charters_navlink__1vIU7 {
    line-height: 30px;
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) {
  .courses_courseButtonGrid__MGGc0 {
    margin-top: 70px;
    width: 100%;
    position: absolute;
  }
}

.courses_courseButton__2C8eF {
  margin-right: 20px;
}

.courses_practicalLessonsGrid__3U9V6 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.courses_practicalLessonsButtonGrid__2asv7 {
}

.courses_trainingInfo__3_N0k {
  margin-top: 40px;
}

.courses_topicCovers__18old {
  margin-top: "118px";
}

.courses_circle__3lQhm {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: red;
  position: absolute;
}

.courses_drawer__KjUOV {
  flex-shrink: 0;
}

.courses_drawerPaper__37E9s {
  overflow-x: hidden;
  background: #efeee8;
}

@media only screen and (min-width: 320px) {
  .courses_drawerPaper__37E9s {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }

  .courses_practicalLessonsGrid__3U9V6 {
    margin-bottom: 5vh !important;
  }

  .courses_practicalLessonsButtonGrid__2asv7 {
    margin-top: 0 !important;
  }

  .courses_trainingInfo__3_N0k {
    margin-top: 40px;
    padding-left: 3vw;
  }

  .courses_topicCovers__18old {
    margin-top: 10vh !important;
    padding-left: 3vw;
  }

  .courses_courseButton__2C8eF {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .courses_drawerPaper__37E9s {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .courses_practicalLessonsGrid__3U9V6 {
    margin-bottom: 5vh !important;
  }

  .courses_practicalLessonsButtonGrid__2asv7 {
    justify-content: flex-end;
    margin-right: 20px !important;
    margin-top: 5vh !important;
  }
  .courses_trainingInfo__3_N0k {
    margin-top: 40px;
    padding-left: 0;
  }

  .courses_topicCovers__18old {
    margin-top: 80px !important;
    padding-left: 0;
  }
}

@media only screen and (min-width: 1224px) {
  .courses_drawerPaper__37E9s {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .courses_practicalLessonsGrid__3U9V6 {
    margin-right: 0px !important;
  }
  .courses_practicalLessonsButtonGrid__2asv7 {
    margin-top: 0 !important;
  }
  .courses_trainingInfo__3_N0k {
    margin-top: 40px;
    padding-left: 0;
  }

  .courses_topicCovers__18old {
    margin-top: 118px;
    padding-left: 0;
  }

  .courses_courseButton__2C8eF {
    margin-right: 20px;
  }
}

