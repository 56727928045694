.pricingContainer {
  border: 2px solid #ff3302;
  margin-bottom: 20px;
  padding: 20px;
  font-family: "heavitasregular";
  color: #ff3302;
  width: 500px;
}

.seaChangeLogoContainer {
  display: flex;
  justify-content: center;
}

.descContainer {
  font-family: "sofia-pro";
  color: #ff3302;
}

.pricingText {
  font-size: 25px;
  margin-bottom: 2vh;
}

.inclText {
  font-size: 12px;
}

.costText {
  float: right;
  clear: right;
}
.lessonText {
  float: right;
  clear: right;
  margin-bottom: 2vh;
}

@media only screen and (min-width: 320px) {
  .pricingContainer {
    max-width: 100%;
    margin: 5vw;
  }

  .drawerPaper {
    overflow-x: hidden;
    margin-top: 10%;
    width: 100%;
  }

  .descContainer {
    font-family: "sofia-pro";
    color: #ff3302;
    padding: 5vw;
  }
  .seaChangeLogoContainer {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .pricingContainer {
    max-width: 100%;
    margin: 0;
    margin-bottom: 30vh;
  }

  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .descContainer {
    font-family: "sofia-pro";
    color: #ff3302;
    padding: 0;
  }
  .seaChangeLogoContainer {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1224px) {
  .pricingContainer {
    max-width: 80%;
    margin: 0;
  }

  .drawerPaper {
    overflow-x: hidden;
    margin-top: 0;
    width: 96%;
  }
  .descContainer {
    font-family: "sofia-pro";
    color: #ff3302;
    padding: 0;
  }
  .seaChangeLogoContainer {
    display: flex;
    justify-content: flex-start;
  }
}
